body {
  font-family: "Tajawal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Tajawal";
}

html:lang(ar) {
  direction: rtl;
}

.text-primary {
  color: #294595 !important;
}


img.loading-img {
  -webkit-animation: mover .7s infinite alternate;
  animation: mover .7s infinite alternate;
}

img.loading-img {
  -webkit-animation: mover .7s infinite alternate;
  animation: mover .7s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}