#partnersContainer {
    min-height: 63vh;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.05rem;
    padding-inline: 0.65rem;
    margin: 15px 0px;
}

.partnerImg {
    width: 95%;
}

.imgContainer:nth-child(18) .partnerImg {
    height: 176px;
}

.imgContainer {
    box-shadow: 2px 7px 0.2em #e5e5e5;
    border-radius: 1.5px;
    max-width: 10.5rem;
    min-width: 9rem;
    min-height: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgContainer:hover {
    transform: scale(1.1);
    box-shadow: 5px 10px 0.4em #88a1ce;
    transition: all ease 0.4s;
}